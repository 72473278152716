import { createApp } from 'vue';
import App from '@learner/App.vue';

// eslint-disable-next-line import/no-extraneous-dependencies
import { createPinia } from 'pinia';
import router from '@learner/router';

import $api from '@learner/services/api';
import '@learner/scss/entry.scss';

const pinia = createPinia();
const app = createApp(App);
app.config.globalProperties.$api = $api;

app.use(pinia);
app.use(router);
app.mount('#app-wrap');
