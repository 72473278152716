import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  toRefs,
  computed,
  watch,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import useStore from '@learner/store';

// eslint-disable-next-line import/no-extraneous-dependencies
import useSalesforceChat from '@shared/src/lib/salesforce-chat';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();
const store = useStore();
const classes = computed((): object => ({ [`${String(route.name)}-page`]: true }));
const {
  user,
  themeClass,
} = toRefs(store);

useSalesforceChat(user, { source: 'learner' });

onBeforeMount(() => {
  const $body = document.querySelector('body');
  if ($body) $body.className = themeClass.value;
});

watch(
  themeClass,
  (newVal: string) => {
    const $body = document.querySelector('body');
    if ($body) $body.className = newVal;
  },
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: _normalizeClass(classes.value)
  }, [
    _createVNode(_component_router_view, { name: "HeaderBar" }),
    _createVNode(_component_router_view)
  ], 2))
}
}

})