import { AxiosError } from 'axios';
import { debounce } from 'ts-debounce';
import {
  ModalProgrammatic as Modal,
  SnackbarProgrammatic as Snackbar,
  DialogProgrammatic as Dialog,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@components-src/lib/programmatics';

let currentApiCalls = 0;
const transformCallBacks = (dialogOpts: DialogOptions) => {
  const obj = {
    ...dialogOpts,
    onConfirm: dialogOpts.confirmCallback || dialogOpts.onConfirm || null,
    onCancel: dialogOpts.cancelCallback || dialogOpts.onCancel || null,
  };
  return obj;
};

export function displayModal(modalOpts: ModalOptions): void {
  Modal.open(modalOpts);
}

export function displayDialog(dialogOpts: DialogOptions): void {
  Dialog.confirm(transformCallBacks(dialogOpts));
}

export function displayDialogPrompt(dialogOpts: DialogOptions): void {
  Dialog.prompt(transformCallBacks(dialogOpts));
}

export function displayDialogConfirm(dialogOpts: DialogOptions): Promise<{result: any}> {
  if (dialogOpts.promisable) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Dialog.confirm(transformCallBacks({
      promisable: true,
      ...transformCallBacks(dialogOpts),
    }));
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Dialog.confirm(transformCallBacks(dialogOpts));
}

export function displayDialogAlert(dialogOpts: DialogOptions): void {
  Dialog.alert(transformCallBacks(dialogOpts));
}

export function displaySnackbar(snackbarOpts: SnackbarOptions): any {
  if (!snackbarOpts.position) {
    // eslint-disable-next-line no-param-reassign
    snackbarOpts.position = 'is-bottom';
  }
  return Snackbar.open(snackbarOpts);
}

export function displaySnackbarSuccess(snackbarOpts: SnackbarOptions): any {
  if (!snackbarOpts.position) {
    // eslint-disable-next-line no-param-reassign
    snackbarOpts.position = 'is-bottom';
  }
  // eslint-disable-next-line no-param-reassign
  snackbarOpts.type = 'is-success';
  return Snackbar.open(snackbarOpts);
}

export function displaySnackbarError(snackbarOpts: SnackbarOptions): any {
  const options = {
    position: 'is-bottom',
    type: 'is-danger',
    duration: 5000,
    ...snackbarOpts,
  };

  return Snackbar.open(options);
}

/**
 * Build error message from server API error, including server message and list of API error details.
 */
const buildServerErrorMessage = (error: Error, prefix?: string) => {
  const messagePrefix = prefix ?? 'Server API Error';

  if (!(error instanceof AxiosError)) {
    const message = (typeof error === 'string') ? error : error?.message;
    if (message) {
      return `<p>${messagePrefix}: ${message}</p>`;
    }

    return messagePrefix;
  }

  const errorData = error?.response?.data.error;
  const message = errorData?.message;

  const details = errorData?.details;
  const detailValues = details ? Object.values(details).flat() : [];

  let markup = `<p>${messagePrefix}: ${message}</p>`;

  if (detailValues.length) {
    markup += `
      <ul style="padding-left: 1.5rem; list-style: disc;">
        ${detailValues.map((msg) => `<li>${msg}</li>`).join('')}
      </ul>
    `;
  }

  return markup;
};

/**
 * Show snackbar with error details from server API response.
 */
export const displayServerError = (error: Error, messagePrefix?: string, snackbarOpts?: SnackbarOptions) => {
  const snackbarArgs = {
    message: buildServerErrorMessage(error, messagePrefix),
    ...snackbarOpts,
  };

  displaySnackbarError(snackbarArgs);
};

/**
 * Debounced function for removing the API indicator.
 * Avoids the spinner "flashing" on the page as it is quickly removed and re-added for sequential API calls.
 */
const removeApiIndicator = debounce(() => {
  if (currentApiCalls < 1) {
    const $elm = document.querySelector('#api-event-indicator');
    $elm?.remove();
  }
}, 50);

/**
 * Creates a window variable, currentApiCalls which contains a count of the current API calls.
 * while the API are move than 0 then we animate.
 */
export const displayApiIndicator = {
  createElement() {
    const $parentElm = document.createElement('div');
    $parentElm.setAttribute('id', 'api-event-indicator');
    $parentElm.classList.add('spinner');

    const $line = document.createElement('div');

    $parentElm.append($line);
    $parentElm.append($line);
    $parentElm.append($line);

    document.body.append($parentElm);
  },

  startCall() {
    currentApiCalls = currentApiCalls ? currentApiCalls + 1 : 1;

    if (!document.querySelector('#api-event-indicator')) {
      this.createElement();
    }
  },

  endCall() {
    currentApiCalls -= 1;

    if (currentApiCalls < 1) {
      removeApiIndicator();
    }
  },
};

export default {
  displayModal,
  displayDialogPrompt,
  displayDialog,
  displayDialogAlert,
  displayServerError,
  displaySnackbar,
  displaySnackbarSuccess,
  displaySnackbarError,
  displayApiIndicator,
};
