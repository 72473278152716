<script setup lang="ts">
import {
  toRefs,
  computed,
  watch,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import useStore from '@learner/store';

// eslint-disable-next-line import/no-extraneous-dependencies
import useSalesforceChat from '@shared/src/lib/salesforce-chat';

const route = useRoute();
const store = useStore();
const classes = computed((): object => ({ [`${String(route.name)}-page`]: true }));
const {
  user,
  themeClass,
} = toRefs(store);

useSalesforceChat(user, { source: 'learner' });

onBeforeMount(() => {
  const $body = document.querySelector('body');
  if ($body) $body.className = themeClass.value;
});

watch(
  themeClass,
  (newVal: string) => {
    const $body = document.querySelector('body');
    if ($body) $body.className = newVal;
  },
);
</script>

<template>
  <div id="app" :class="classes">
    <router-view name="HeaderBar" />
    <router-view/>
  </div>
</template>
