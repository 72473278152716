import {
  ref,
  computed,
} from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { defineStore } from 'pinia';
import $api from '@learner/services/api';
import { userDarkModePreference, saveDarkModeUserPreference } from '@shared/src/lib/dark-mode';

/**
 * Private function to fetch user company.
 */
const getCompany = async (user: User): Promise<Company> => {
  const companyResponse: APIResponse = await $api.get(`/companies/${user.company.id}`);

  if (!companyResponse.data?.data) {
    throw new Error('could not get company data');
  }

  return companyResponse.data.data;
};

const setSessionTimeoutProperties = (logoutUrl: string): void => {
  // @ts-ignore
  $api.defaults.sessionTimeoutRedirect = logoutUrl;
  // @ts-ignore
  $api.defaults.sessionTimeoutDetection = true;
};

export default defineStore(
  'store',
  () => {
    const user = ref<null | User>(null);
    const darkMode = ref(userDarkModePreference());
    const isLoading = ref(false);
    const fetchUserPromise = ref();
    const fetchCompanyPromise = ref();
    const flashData = ref(null);
    const language = ref('en');

    // computed values
    const themeClass = computed((): string => (darkMode.value ? 'is-dark-mode' : 'is-light-mode'));

    const userLinks = computed((): UserLink[] => [
      {
        title: 'Update Profile',
        to: {
          name: 'profile',
        },
      },
      {
        title: 'Logout',
        to: {
          name: 'logout',
        },
      },
    ]);

    const company = computed(() => user.value?.company);

    const companyName = computed((): string | null => user.value?.company?.name || null);

    const translatedLang = computed((): string => language.value);

    // methods
    const setLang = (payload: string): void => {
      language.value = payload;
    };

    const clearUserState = (): void => {
      user.value = null;
    };

    const setIsLoading = (payload: boolean): void => {
      isLoading.value = payload;
    };

    const setUser = (payload: User | null): void => {
      user.value = payload;
    };

    const setFlashData = (payload: any): void => {
      flashData.value = payload;
    };

    const toggleDarkMode = (payload: boolean): void => {
      darkMode.value = payload;

      saveDarkModeUserPreference(payload);
    };

    // async actions
    /**
     * This is run on app initialization before any restricted paths are requested.
     */
    const fetchAuthUser = async (): Promise<boolean> => {
      if (fetchUserPromise.value) {
        await fetchUserPromise.value;

        if (fetchCompanyPromise.value) {
          await fetchCompanyPromise.value;
        }

        return true;
      }

      try {
        setIsLoading(true);

        fetchUserPromise.value = $api.get('/me');
        const userResponse = await fetchUserPromise.value;
        const userResponseData = userResponse.data.data;

        fetchCompanyPromise.value = getCompany(userResponseData);
        userResponseData.company = await fetchCompanyPromise.value;
        if (userResponseData.company.logout_url) {
          localStorage.setItem('logout_url', userResponseData.company.logout_url);
        }

        setSessionTimeoutProperties(userResponseData.company.logout_url);

        setUser(userResponseData);
        return true;
      } catch (error) {
        // probably want to log the error here
        setUser(null);
        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    const login = async (payload: LoginPayload): Promise<boolean> => {
      const path = '/login';
      const creds: LoginRequestBody = {
        username: payload.username,
        password: payload.password,
      };

      if (payload.mfa_token) {
        creds.mfa_token = payload.mfa_token;
      }

      const urlParams = new URLSearchParams(window.location.search);

      if (payload.remember !== undefined) {
        creds.remember = payload.remember;
      }

      await $api.get(`${process.env.VUE_APP_API_URL}/api/csrf-cookie`);

      try {
        setIsLoading(true);
        const userResponse: APIResponse = await $api.post(path, creds);

        if (!userResponse.data?.data?.user) {
          throw new Error('could not login');
        }

        const { user: userLocal } = userResponse.data.data;

        if (userLocal.role !== 'Student') {
          const params = urlParams.get('redirect') ?? '';
          window.location.href = decodeURIComponent(`${process.env.VUE_APP_ADMIN_URL}${params}`);
        }

        userLocal.company = await getCompany(userLocal);
        if (userLocal.company.logout_url) {
          localStorage.setItem('logout_url', userLocal.company.logout_url);
        }

        setSessionTimeoutProperties(userLocal.company.logout_url);

        setUser(userLocal);

        return true;
      } finally {
        setIsLoading(false);
      }
    };

    /**
     * Performs a login from an encrypted username token. Assumes the "default" password is set for the user.
     */
    const loginDefault = async (token: string) => {
      await $api.get(`${process.env.VUE_APP_API_URL}/api/csrf-cookie`);

      try {
        setIsLoading(true);

        const userResponse = await $api.post('/login/default', { token });
        const { user: userLocal } = userResponse.data.data;

        userLocal.company = await getCompany(userLocal);
        if (userLocal.company.logout_url) {
          localStorage.setItem('logout_url', userLocal.company.logout_url);
        }

        setSessionTimeoutProperties(userLocal.company.logout_url);

        setUser(userLocal);

        return true;
      } finally {
        setIsLoading(false);
      }
    };

    const logout = async (): Promise<string | null> => {
      setIsLoading(true);

      try {
        const response = await $api.post('/logout');

        clearUserState();
        // @ts-ignore
        $api.defaults.sessionTimeoutDetection = false;

        if (response.data?.logout_url?.length) {
          return response.data.logout_url;
        }

        return null;
      } finally {
        setIsLoading(false);
      }
    };

    const clearFlashData = (payload: unknown): void => {
      setFlashData(payload);
    };

    return {
      user,
      darkMode,
      isLoading,
      fetchUserPromise,
      fetchCompanyPromise,
      flashData,
      language,
      themeClass,
      userLinks,
      // computed
      company,
      companyName,
      translatedLang,
      // methods
      setLang,
      clearUserState,
      setIsLoading,
      setUser,
      setFlashData,
      toggleDarkMode,
      // async methods
      clearFlashData,
      logout,
      loginDefault,
      login,
      fetchAuthUser,
    };
  },
);
