import {
  watch,
  onBeforeMount,
} from 'vue';

const defaultOptions = {
  source: 'learner',
};

// Dictionary of hard-coded Salesforce chat configs.
const configs = {
  dev: {
    initUrl1: 'https://traliant--dev1.sandbox.my.salesforce.com',
    initUrl2: 'https://traliant--dev1.sandbox.my.site.com/',
    gslId: '00DVF000004g8Jp',
    gslName: 'Chatbot_Queue',
    baseLiveAgentContentURL: 'https://c.la2s-core2.sfdc-lywfpd.salesforceliveagent.com/content',
    deploymentId: '572VF000000KTp3',
    buttonId: '573VF000000Dkkz',
    baseLiveAgentURL: 'https://d.la2s-core2.sfdc-lywfpd.salesforceliveagent.com/chat',
    eswLiveAgentDevName: 'Chatbot_Queue',
    isOfflineSupportEnabled: false,
  },
  prod: {
    initUrl1: 'https://traliant.my.salesforce.com',
    initUrl2: 'https://traliant.my.site.com/',
    gslId: '00D36000000HlEo',
    gslName: 'Chat_Queue',
    baseLiveAgentContentURL: 'https://c.la1-core2.sfdc-lywfpd.salesforceliveagent.com/content',
    deploymentId: '572VO0000008401',
    buttonId: '573VO000000TSyc',
    baseLiveAgentURL: 'https://d.la1-core2.sfdc-lywfpd.salesforceliveagent.com/chat',
    eswLiveAgentDevName: 'Chat_Queue',
    isOfflineSupportEnabled: false,
  },
};

/**
 * Inject the Salesforce chat widget into the page when a valid configuration
 * name is set in process.env.VUE_APP_SF_CHAT_CONFIG.
 */
export default function useSalesforceChat(user, options = defaultOptions) {
  const { source } = options;
  const configName = (process.env.VUE_APP_SF_CHAT_CONFIG ?? '').toLowerCase();
  const isChatEnabled = (() => {
    // Do not show button on course player page.
    const isCoursePlayerPage = window.location.pathname.match(/\/course\/\d+/) !== null;
    if (isCoursePlayerPage) {
      return false;
    }

    // Show warning and set false if a config name is set, but not valid.
    // eslint-disable-next-line no-prototype-builtins
    if (!!configName && !configs.hasOwnProperty(configName)) {
      console.warn(`Invalid Salesforce Chat config name: ${configName}`);
      return false;
    }

    // Chat is enabled if we have a valid config name from env.
    return !!configName;
  })();

  /**
   * Ensure the Salesforce Chat script is loaded and the required object is present.
   */
  const validateScriptLoaded = () => {
    if (!window.embedded_svc) {
      console.warn('Salesforce embedded_svc object not found.');
      return false;
    }

    return true;
  };

  /**
   * Append script element into the document.
   *
   * Returns a promise that resolves once the script is done loading.
   */
  const injectScript = (id, scriptSrc) => {
    const existingScriptElement = document.querySelector(`#${id}`);
    if (existingScriptElement) return Promise.resolve(existingScriptElement);

    const script = document.createElement('script');
    script.id = id;
    script.setAttribute('src', scriptSrc);

    // Return promise that resolves after the script has been loaded by the browser.
    return new Promise((resolve) => {
      script.addEventListener('load', () => { resolve(script); });
      document.body.appendChild(script);
    });
  };

  const loadScripts = async () => {
    // Inject standard Salesforce script.
    await injectScript('salesforce-esw', 'https://service.force.com/embeddedservice/5.0/esw.min.js');
  };

  /**
   * Apply setting values to Salesforce Chat widget object.
   */
  const applyChatSettings = () => {
    if (!validateScriptLoaded()) {
      return;
    }

    window.embedded_svc.settings.displayHelpButton = true;
    window.embedded_svc.settings.language = window.navigator.language;

    window.embedded_svc.settings.prepopulatedPrechatFields = {
      FirstName: user.value?.first_name ?? '',
      LastName: user.value?.last_name ?? '',
      Email: user.value?.email ?? '',
    };

    window.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: 'First Name',
        transcriptFields: ['First_Name__c'],
      },
      {
        label: 'Last Name',
        transcriptFields: ['Last_Name__c'],
      },
      {
        label: 'Email',
        transcriptFields: ['Email__c'],
      },
      {
        label: 'authenticated',
        value: !!user.value,
        transcriptFields: ['Authenticated__c'],
      },
      {
        label: 'Source__c',
        value: source,
        transcriptFields: ['Source__c'],
      },
    ];

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
  };

  /**
   * Initialize the Salesforce chat widget.
   */
  const initChat = () => {
    if (!validateScriptLoaded()) {
      return;
    }

    // Get config object from config dictionary.
    const thisConfig = configs[configName];

    window.embedded_svc.init(
      thisConfig.initUrl1,
      thisConfig.initUrl2,
      'https://service.force.com',
      thisConfig.gslId,
      thisConfig.gslName,
      {
        baseLiveAgentContentURL: thisConfig.baseLiveAgentContentURL,
        deploymentId: thisConfig.deploymentId,
        buttonId: thisConfig.buttonId,
        baseLiveAgentURL: thisConfig.baseLiveAgentURL,
        eswLiveAgentDevName: thisConfig.eswLiveAgentDevName,
        isOfflineSupportEnabled: thisConfig.isOfflineSupportEnabled,
      },
    );
  };

  // Inject chat JS script and init chat before mount.
  onBeforeMount(async () => {
    if (isChatEnabled) {
      await loadScripts();
      applyChatSettings();
      initChat();
    }
  });

  // When user changes, reconfigure the chat settings with the new user data.
  watch(
    user,
    () => {
      if (isChatEnabled) {
        applyChatSettings();
      }
    },
  );
}
