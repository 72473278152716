import {
  nextTick,
  toRefs,
} from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import useStore from '@learner/store';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TSplashScreenModal from '@components/SplashScreen/SplashScreenModal';
import routes from './routes';

const DEFAULT_TITLE = 'Traliant';

// create the router object
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
});

const toggleBodyClass = (className: string, command: null | string) => {
  const $body = document.querySelector('body');
  if (!$body) { return; }

  if (command && ['add', 'remove'].includes(command)) {
    $body.classList[command](className);
  } else {
    $body.classList.toggle(className);
  }
};

router.beforeEach((to) => {
  if (to.name === 'login') {
    toggleBodyClass('has-blue-bg', 'add');
  }
});

router.afterEach((to) => {
  if (to.name === 'login') {
    toggleBodyClass('has-blue-bg', 'remove');
  }
});

/**
 * Check authenticated routes
 *
 * If the requested route requires authentication (requiresAuth meta field on the route)
 * and a user is not set then attempt to get the user via the v3/me endpoint
 *
 * If that works and the user is set, then proceed. Otherwise redirect to the login page.
 */
router.beforeEach((to) => {
  const { requiresAuth } = to.meta;
  const loginPath = { path: '/login', query: { redirect: to.fullPath } };

  if (requiresAuth && typeof to.meta.bypassIf === 'function' && to.meta.bypassIf(to)) {
    return;
  }

  // inject store
  const $store = useStore();
  const {
    user,
  } = toRefs($store);

  const {
    fetchAuthUser,
  } = $store;

  const closeModal = (modal: any = null) => {
    if (modal) {
      modal.close();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initUser = (modal: any = null) => {
    fetchAuthUser()
      .then(() => {
        closeModal(modal);
        if (!user.value) router.push(loginPath);
      }).catch(() => {
        closeModal(modal);
        router.push(loginPath);
      });
  };

  if (requiresAuth && !user.value) {
    if (to.meta.showSplash !== false) {
      TSplashScreenModal({
        onAfterEnter: initUser, // This callback will pass in modal instance as parameter.
      });
    } else {
      initUser();
    }
  }
});

/**
 * Check if a user is in a lobby
 *
 * Lobbies are pages like the login in page - its typically where a user goes when
 * they are waiting to access a restricted area.
 */
router.beforeEach((to, from, next) => {
  const lobby = to.matched.some((record) => record.meta.lobby);
  const rootPath = { path: '/', query: { redirect: to.fullPath } };
  const $store = useStore();
  const {
    user,
  } = toRefs($store);

  const {
    fetchAuthUser,
  } = $store;

  if (lobby && !user.value) {
    fetchAuthUser()
      .then(() => {
        if (!user.value) {
          next();
        } else {
          next(rootPath);
        }
      })
      .catch(() => {
        next();
      });
  } else {
    next();
  }
});

// set the page title
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to?.meta?.title ? `Traliant - ${to.meta.title}` : DEFAULT_TITLE;
  });
});

export default router;
